import { camelCase } from 'scule'

export default function () {
  const { $sitewideConfig } = useNuxtApp()
  const route = useRoute()

  // takes a prefix (category, brand, category-custom, productline, etc..) and any overrides and returns the right template to use for title/description
  function resolveTemplates(prefix: string, pageTemplates?: Record<string, string>): ResolvedMetaTemplate {
    const sitewideTemplates = getSitewideTemplates(camelCase(prefix))
    const templates = { ...sitewideTemplates, ...pageTemplates }
    let title = templates.title || ''
    let description = templates.description || ''

    if (templates.titleFitment && route.params.makeSlug) title = templates.titleFitment

    if (templates.descriptionFitment && route.params.makeSlug) description = templates.descriptionFitment // old
    else if (route.params.year && templates.descriptionYear) description = templates.descriptionYear
    else if (route.params.modelSlug && templates.descriptionModel) description = templates.descriptionModel
    else if (route.params.makeSlug && templates.descriptionMake) description = templates.descriptionMake

    return {
      title,
      description,
    }
  }

  interface ResolvedMetaTemplate {
    title: string
    description: string
  }

  function getSitewideTemplates(prefix: string) {
    if (!$sitewideConfig.seo) return null

    const template = {}
    const templateIndex = $sitewideConfig.seo.content

    const templateKeys = [
      'description',
      'descriptionMake',
      'descriptionModel',
      'descriptionYear',
      'title',
      'titleFitment',
    ]

    templateKeys.forEach((key) => {
      const typeKey = `${prefix}${key[0].toUpperCase()}${key.substring(1)}` // description -> brandDescription
      if (templateIndex[typeKey]) template[key] = templateIndex[typeKey]
    })

    // was there anything actually filled out?
    if (Object.keys(template).length > 0) return template
    return null
  }

  return {
    resolveTemplates,
  }
}
